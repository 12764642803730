import React from 'react';
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'

class EnRace981GuideComp  extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            step: 1
		};
    }

    chageStep = step => () => {
        if (this.state.step !== step) {
            this.setState({step: step})
            window.scrollTo(0,0)
        }
	};

	render() {
        const {t} = this.props;
		return (
			<>
                <div className="content-wrap hearing-impaired-content-wrap race-981">
                    <div className="visual-wrap">
                        <div className="img">
                            <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/bitmap.png" alt="race981"/>
                        </div>
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap color1">
                                    <p className="s-text typo1">RACE 981</p>
                                    <p className="m-text">Race <br/> through the Wind!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-con-wrap">
                        <div className="tab-wrap">
                            <div className="tab-menu-wrap">
                                <ul className="tab-menu tab-menu-3">
                                    <li>
                                        <a onClick={this.chageStep(1)} className={this.state.step === 1 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">APP</span>
                                                <span className="txt">HOW TO USE</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={this.chageStep(2)} className={this.state.step === 2 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">B2</span>
                                                <span className="txt">CENTRAL</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={this.chageStep(3)} className={this.state.step === 3 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">G</span>
                                                <span className="txt">BOARDING</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-con-wrap">
                                <div className="inner">
                                    <div className="tab-con" style={{display: (this.state.step === 1) ? "" : "none"}}>
                                        <div className="notice">
                                            <div className="notice-con">
                                                <p className="ttl">Available to Board</p>
                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        <p>· Age : over 14</p>
                                                        <p>· Height : 150 ~ 190cm</p>
                                                        <p>· To board with a passenger, <br/>
                                                            driver must be over 19</p>
                                                        <p>· No high heels or pump shoes allowed</p>
                                                        <p>· Drivers must wear Closed-Toe Shoes <br/>
                                                            (for sandals etc., you can purchase
                                                            overshoes from GARAGE981)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">HOW TO USE THE 9.81 APP</p>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-app.png" alt="981 PARK APP"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="notice-guide">
                                            <div className="notice-guide-con">
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. Download the app and
                                                            register the ticket.</div>
                                                        <div className="txt">
                                                            You may download the app through the QR code on the ticket! If you link your ticket within the app, you can receive your race videos and records!
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. ALWAYS "SAFETY" FIRST.</div>
                                                        <div className="txt">
                                                            Please watch the safety video before enjoying the race!
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. Our APP has all the answers
                                                            you need!</div>
                                                        <div className="txt">
                                                            <p>You may check which ticket you have through the app. You can also check where and how many times you can enjoy each activities, and your next race time and course for you to start on time!</p>
                                                            <p>If you have Course 3>1>2 on your screen, you must start with Course 3 first, then Course 1, and then Course 2 in order.
                                                                For Course 1>1>2, you must race the Course 1 twice, before moving on to the Course 2.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 2) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">CENTRAL</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-central.png" alt="센트럴"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        Do you see your race time on the central screen? It's time for you to enter and enjoy the race!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">Please read and follow <br/>
                                                        all the instructions below.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. Let's put away your
                                                            belonigings in the locker</div>
                                                        <div className="txt">
                                                            Please stow away everything except your phone.
                                                        </div>
                                                        <div className="ps-txt">
                                                            Password-lockers are free to use and are located next to the emergency exit.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">GATE</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-gate.png" alt="게이트"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        Our crew may provide some verbal instructions when you enter! Please show them on the APP that you have watched the safety video.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">Please read and follow <br/>
                                                        all the instructions below.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. Tag your ticket and enter
                                                            through the gate.</div>
                                                        <div className="txt">
                                                            An NFC chip is embedded at "9.81" logo on your ticket. Tag the logo on the Arrows on the gate.
                                                            You may go through when the gate turns white.
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. Important to note!</div>
                                                        <div className="txt">
                                                            Please tag your ticket once the person in front of you has passed through and the light on the gate has been turned off.
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. Things you should know
                                                            before you race:</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2"><b>1</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>Speed of the GR.</b></p>
                                                                    <p className="txt">There is a maximum speed limit,
                                                                        and the beep noise during the
                                                                        race indicates that you are about
                                                                        to reach the <b>Max speed.</b>
                                                                        If the GR reaches the maximum
                                                                        speed, the <b>BRAKE</b> operates
                                                                        automatically and reduces the
                                                                        speed. So, don't be surprised and
                                                                        enjoy the race.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2"><b>2</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>Obstacles during the race.</b></p>
                                                                    <p className="txt">If something appears in front of
                                                                        you on the track, please slow
                                                                        down. If your fun was interrupted
                                                                        due to an opstacle, please let us
                                                                        know!</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 3) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">BOARDING PLATFORM</p>
                                                    <div className="location">G</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-boarding.png" alt="탑승장"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        Please follow the crew's guidance, when you get on or off the GR. Our crews are pro at making your races so much smoother.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">Please read and follow <br/>
                                                        all the instructions below.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. Let's safely board the GR!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Put your both feet on GR and
                                                                        sit down safely.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Tag the '9.81' Lgoo of your ticket
                                                                        onto the NFC reader on the right
                                                                        side of the display.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Put your phone (belongings) in
                                                                        your pocket or behind your back.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">For GR-E racer, cross the seat belts
                                                                        where are over both shoulders in
                                                                        an X-shpae and buckle them.
                                                                        For GR-D racer, put both arms
                                                                        through the seat belt and buckle
                                                                        them.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">2. Don't forget about the
                                                            Scream Booster when you are
                                                            on the Course 3!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Go in to "Activity" tab on
                                                                        the 9.81 APP.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Tap the scream booster banner
                                                                        at the top.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Press the "Start Game" button,
                                                                        then tag the ticket on GR.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Mount your phone on the phone
                                                                        case in front of your seat.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">You are ready to start when
                                                                        'READY' is displayed on your APP.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">6</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Please wait in front of the start
                                                                        line. The race will start when you
                                                                        take your foot off the brake.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">7</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">You must fill the gauge on the
                                                                        screen by screaming for about
                                                                        3~5 seconds to use the Scream
                                                                        Booster.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">3. How to Warm-up before
                                                            the race:</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">You must control the speed only
                                                                        with the brake under your right
                                                                        foot.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Hold on the brake until the crew's
                                                                        instruction, and stop behind the
                                                                        yellow line</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-1.svg" alt="준비 운동1"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Check the steering wheel by
                                                                        rotating it left and right, then stop
                                                                        behind the white line on the floor.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-2.svg" alt="준비 운동2"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Start when the traffic light turns 	from red to green.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-3.svg" alt="준비 운동3"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">4. Remember these while
                                                            racing:</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Using your phone, stopping
                                                                        intentionally, colliding on the
                                                                        tracks, or taking either hand off
                                                                        the steering wheel will make your
                                                                        race DNF (DNF = Disqualified,
                                                                        so you cannot receive your
                                                                        records and video).</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Slow down after crossing the
                                                                        finish line.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/safety.gif" alt="레이스 중 주의사항"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Drive slowly up to the blue line
                                                                        then stop completely. Press on
                                                                        the brake for 3 seconds and
                                                                        release it. Your GR will return
                                                                        automatically from there on.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">If any problem arises or you get
                                                                        too close to the other GR in front
                                                                        of you, you can press on the brake
                                                                        and notify a crew nearby.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">Please unfasten your seatbelt and
                                                                        get off the GR safely once it stops
                                                                        completely.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default withTranslation()(EnRace981GuideComp);