import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";
class AboutGROC extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
            tab: "individual"
        };
	}

    changeTab = (type) => () => {
        if (type === "individual") {
            if (this.state.tab !== "individual") {
                this.setState({tab: "individual"})
            }
        } else {
            if (this.state.tab !== "team") {
                this.setState({tab: "team"})
            }
        }
    }

	componentDidMount() {
    }

	render() {
		const {t} = this.props;
		return (
            <div className="content-wrap sub-content sub-groc sub-groc-v2">
                <div className="visual-wrap">
                    <div className="visual-slide-wrap">
                        <div className="visual-slide-list">
                            <div className="slide">
                                <div className="img">
                                    <img src="https://asset.981park.com/www/common/web/img/sub/groc/slide-groc-about1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
                                </div>
                            </div>
                        </div>

                        <div className="visual-slide-text">
                            <div className="inner">
                                <div className="con">
                                    <div className="text-wrap color1">
                                        <p className="s-text typo1">ABOUT GROC CHAMPIONSHIP FINAL</p>
                                        <p className="m-text">{t('Groc_top_class1')} <br/> {t('Groc_top_class2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-con-wrap">
                    <div className="inner">		
                        <div className="list list-type7">
                            <div className="list-con">
                                <p className="lg-ttl color2">{t('Groc_groc_title')}</p>
                                <div className="txt-wrap">
                                    <div className="txt">
                                        {t('Groc_groc_info_1')}<br/>
                                        {t('Groc_groc_info_2')}<br/>
                                        {t('Groc_groc_info_3')}
                                    </div>
                                </div>

                                <div className="txt-table">
                                    <ul>
                                        <li className="item">
                                            <div className="ttl">{t('Groc_groc_info_4')}</div>
                                            <div className="txt-wrap">
                                                <div className="txt">
                                                    <b>{t('Groc_groc_info_4_1')}</b>
                                                    <span className="txt ps-txt pl-10">{t('Groc_groc_info_4_2')}</span> <br/><br/>
                                                    {t('Groc_groc_info_4_3')} <br/>
                                                    {t('Groc_groc_info_4_4')} <br/><br/>
                                                    <span>{t('Groc_groc_info_4_5')}</span><span className="txt pl-10">{t('Groc_groc_info_4_5_1')}</span><span className="txt pl-10">{t('Groc_groc_info_4_5_2')}</span> <br/>
                                                    <span>{t('Groc_groc_info_4_6')}</span><span className="txt pl-10">{t('Groc_groc_info_4_6_1')}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="item">
                                            <div className="ttl">{t('Groc_groc_info_5')}</div>
                                            <div className="txt-wrap">
                                                <div className="txt">
                                                    {t('Groc_groc_info_5_1')} <br/>
                                                    {t('Groc_groc_info_5_2')}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="item">
                                            <div className="ttl">{t('Groc_groc_info_6')}</div>
                                            <div className="txt-wrap">
                                                <div className="txt">
                                                    {t('Groc_groc_info_6_1')} <br/>
                                                    {t('Groc_groc_info_6_2')} <br/><br/>
                                                    {t('Groc_groc_info_6_3')} <br/>
                                                    {t('Groc_groc_info_6_4')}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="groc-season-info">
                                    <div className="table-info">
                                        <ul>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">GROC SEASON</div>
                                                    <div className="txt">
                                                        {t('Groc_season_1')} <br/>
                                                        {t('Groc_season_2')}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">GROC Championship Final</div>
                                                    <div className="txt">
                                                        {t('Groc_season_final_1')} <br/>
                                                        {t('Groc_season_final_2')}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row-table">
                                                    <div className="item">
                                                        <div className="ttl"><span className="typo2">{t('Groc_individual_1')}</span> {t('Groc_individual_2')}</div>
                                                        <div className="txt-wrap">
                                                            <div className="txt">
                                                                <b>{t('Groc_season_individual_1')}</b> <br/>
                                                                {t('Groc_season_individual_2')} <br/>
                                                                {t('Groc_season_individual_3')}
                                                            </div>
                                                            <div className="txt">
                                                                <b>{t('Groc_season_individual_4')}</b> <br/>
                                                                {t('Groc_season_individual_5')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="ttl"><span className="typo2">{t('Groc_team_1')}</span> {t('Groc_team_2')}</div>
                                                        <div className="txt-wrap">
                                                            <div className="txt">
                                                                <b>{t('Groc_season_team_1')}</b> <br/>
                                                                {t('Groc_season_team_2')} <br/>
                                                                {t('Groc_season_team_3')} <br/>
                                                                <span> <br/> </span>
                                                            </div>
                                                            <div className="txt">
                                                                <b>{t('Groc_season_team_4')}</b> <br/>
                                                                {t('Groc_season_team_5')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="groc-season-table">
                                    <p className="lg-ttl">{t('Groc_season_info')}</p>

                                    <div className="table-info table-info-style1">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">GROC 2024 Championship Final</span><span className="sm-txt">12.01</span></span>
                                        </div>
                                    </div>

                                    <div className="table-info">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">SEASON 2024</span><span className="sm-txt">SINGLES</span></span>
                                        </div>
                                        <ul className="table-list table-list3">
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 1</div>
                                                    <div className="date typo2">01.01 ~ 01.28</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 2</div>
                                                    <div className="date typo2">01.29 ~ 02.25</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 3</div>
                                                    <div className="date typo2">02.26 ~ 03.24</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 4</div>
                                                    <div className="date typo2">03.25 ~ 04.21</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 5</div>
                                                    <div className="date typo2">04.22 ~ 05.19</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 6</div>
                                                    <div className="date typo2">05.20 ~ 06.16</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 7</div>
                                                    <div className="date typo2">06.17 ~ 07.14</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 8</div>
                                                    <div className="date typo2">07.15 ~ 08.11</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 9</div>
                                                    <div className="date typo2">08.12 ~ 09.08</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="rowspan">WEEK</td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 10</div>
                                                    <div className="date typo2">09.09 ~ 10.06</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 11</div>
                                                    <div className="date typo2">10.07 ~ 11.03</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2 center">POST ROUND</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">11.04 ~ 01.05</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="table-info table-info-style2">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">SEASON 2024</span><span className="sm-txt">TEAMS</span></span>
                                        </div>
                                        <ul className="table-list table-list2">
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">FINAL FOUR TEAM</div>
                                                    <div className="date typo2">12.01</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">TEAM EVENT</div>
                                                    <div className="date typo2">01.01 ~ 11.03</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>	
                        </div>
                        <div className="list list-type8">
                            <div className="list-con">
                                <p className="lg-ttl">{t('Groc_season_member')}</p>
                                <div className="txt-wrap">
                                    <div className="txt">
                                        {t('Groc_season_member_sub')}
                                    </div>
                                </div>
                            </div>	
                        </div>				
                        
                        <div className="list list-type6">
                            <div className="tab-wrap">
                                <ul className="tab-menu tab-menu-2">
                                    <li><a style={{cursor: "pointer"}} onClick={this.changeTab("individual")} className={(this.state.tab === "individual" ? "selected" : "")}>{t('Groc_individual')}</a></li>
                                    <li><a style={{cursor: "pointer"}} onClick={this.changeTab("team")} className={(this.state.tab === "team" ? "selected" : "")}>{t('Groc_team')}</a></li>
                                </ul>

                                <div className="tab-con-wrap">
                                    <div className="tab-con" style={{display: (this.state.tab === "individual" ? "block" : "none")}}>
                                        <div className="con">
                                            <div className="img">
                                                <img src="https://asset.981park.com/www/common/web/img/sub/groc/groc-tab1.png" alt="9.81 파크"/>
                                            </div>									
                                        </div>
                                        <div className="list-con">
                                            <div className="txt-table">
                                                <ul>
                                                    <li className="item">
                                                        <div className="ttl">{t('Groc_individual_sub_1')}</div>
                                                        <div className="txt">
                                                            {t('Groc_individual_sub_1_1')}<br/><br/>
                                                            {t('Groc_individual_sub_1_2')}<br/><br/>
                                                            {t('Groc_individual_sub_1_3')}<br/><br/>
                                                            {t('Groc_individual_sub_1_4')}<br/><br/>
                                                            {t('Groc_individual_sub_1_5')}<br/>
                                                            {t('Groc_individual_sub_1_6')}
                                                        </div>
                                                    </li>
                                                    <li className="item">
                                                        <div className="ttl">{t('Groc_individual_sub_2')}</div>
                                                        <div className="txt">
                                                            {t('Groc_individual_sub_2_1')}<br/><br/>
                                                            {t('Groc_individual_sub_2_2')}<br/><br/>
                                                            {t('Groc_individual_sub_2_3')}<br/>
                                                            {t('Groc_individual_sub_2_4')}<br/>
                                                            {t('Groc_individual_sub_2_5')}<br/>
                                                            {t('Groc_individual_sub_2_6')}<br/>
                                                            {t('Groc_individual_sub_2_7')}<br/>
                                                            {t('Groc_individual_sub_2_8')}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="tab-con" style={{display: (this.state.tab === "team" ? "block" : "none")}}>
                                        <div className="con">
                                            <div className="img">
                                                <img src="https://asset.981park.com/www/common/web/img/sub/groc/groc-tab2.png" alt="9.81 파크"/>
                                            </div>									
                                        </div>
                                        <div className="list-con">
                                            <div className="txt-table">
                                                <ul>
                                                    <li className="item">
                                                        <div className="ttl">{t('Groc_team_sub_1')}</div>
                                                        <div className="txt">
                                                            {t('Groc_team_sub_1_1')}<br/>
                                                            {t('Groc_team_sub_1_2')}<br/>
                                                            {t('Groc_team_sub_1_3')}
                                                        </div>
                                                    </li>
                                                    <li className="item">
                                                        <div className="ttl">{t('Groc_team_sub_2')}</div>
                                                        <div className="txt">
                                                            {t('Groc_team_sub_2_1')}<br/>
                                                            {t('Groc_team_sub_2_2')}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="txt ps-txt">
                                    <span>{t('Groc_sub_1')}</span>
                                    <span>{t('Groc_sub_2')}</span>
                                    <span>{t('Groc_sub_3')}</span>
                                    <span>{t('Groc_sub_4')}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}
export default withTranslation()(AboutGROC);