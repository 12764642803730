import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';

class RankingComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			tap: "Race"
		};
	}

	selectTap = course => () => {
        switch (course) {
            case "Race":
				if (this.state.tap !== "Race") {
					this.setState({tap: "Race"})
				}
                break;
            case "Round":
                if (this.state.tap !== "Round") {
					this.setState({tap: "Round"})
				}
                break;
			default :
				if (this.state.tap !== "Race") {
					this.setState({tap: "Race"})
				}
        }        
	};

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/race/slide-ranking-system1.png" alt="레이스 981을 게임처럼 즐겨보자!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">GRAVITY RACER</p>
									<p className="m-text">{t('GR_title1')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">	
						<div className="list list-type5">
							<div className="list-con">
								<p className="lg-ttl">Ranking System 랭킹시스템</p>
								<div className="txt-wrap">
									<div className="lg-txt">
									{t('Ranking_sub_c1')} <br/>
									{t('Ranking_sub_c1_1')} <br/>
									{t('Ranking_sub_c1_2')}
									</div>
								</div>
								<div className="button-wrap">
									<a className="link-detail-more">{t('Ranking_sub_c2')}</a>
								</div>
							</div>	
						</div>
						<div className="list list-type6">
							<div className="tab-wrap">
								<ul className="tab-menu tab-menu-2">
									<li><a onClick={this.selectTap("Race")} className={(this.state.tap === "Race") ? "selected" : ""} style={{cursor: "pointer"}}>{t('Ranking_sub_c3')}</a></li>
									<li><a onClick={this.selectTap("Round")} className={(this.state.tap === "Round") ? "selected" : ""} style={{cursor: "pointer"}}>{t('Ranking_sub_c4')}</a></li>
								</ul>

								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.tap === "Race") ? "" : "none"}}>
										<div className="list-con">
											<p className="txt">
											{t('Ranking_sub_tab1_c1')} <br/>
											{t('Ranking_sub_tab1_c2')}
											</p>
										</div>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/web/img/sub/race/ranking-system-tab1.png" alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Ranking_sub_c')}</p>	
												<ul>
													<li><p className="txt">{t('Ranking_sub_tab1_c3')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab1_c4')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab1_c5')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab1_c6')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab1_c7')}</p></li>
												</ul>
												<p className="txt ps-txt">{t('Ranking_sub_tab1_c8')}</p>	
											</div>
										</div>
									</div>
									
									<div className="tab-con" style={{display: (this.state.tap === "Round") ? "" : "none"}}>
										<div className="list-con">
											<p className="txt">
												{t('Ranking_sub_tab2_c1')} <br/>
												{t('Ranking_sub_tab2_c2')}
											</p>
										</div>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/web/img/sub/race/ranking-system-tab2.png" alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Ranking_sub_tab2_c3')}</p>	
												<ul>
													<li><p className="txt">{t('Ranking_sub_tab2_c4')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c5')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c6')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c7')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c8')}</p></li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Ranking_sub_tab2_c9')}</p>	
												<ul>
													<li><p className="txt">{t('Ranking_sub_tab2_c10')} </p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c11')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c12')}</p></li>
													<li><p className="txt">{t('Ranking_sub_tab2_c13')}</p></li>
												</ul>
												
												<p className="txt ps-txt">
												{t('Ranking_sub_tab2_c14')} <br/>
												{t('Ranking_sub_tab2_c15')}
												</p>	
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(RankingComp);