import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n"; //eslint-disable-line no-unused-vars
class AboutGROC extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
            tab: "individual"
        };
	}

    changeTab = (type) => () => {
        if (type === "individual") {
            if (this.state.tab !== "individual") {
                this.setState({tab: "individual"})
            }
        } else {
            if (this.state.tab !== "team") {
                this.setState({tab: "team"})
            }
        }
    }

	componentDidMount() {
    }

	render() {
		const {t} = this.props;
		return (
            <div className="content-wrap sub-content sub-groc sub-groc-v2">
                <div className="visual-wrap">
                    <div className="visual-slide-wrap">
                        <div className="visual-slide-list">
                            <div className="slide">
                                <div className="img">
                                    <img src="https://asset.981park.com/www/common/web/img/sub/groc/slide-groc-about1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
                                </div>
                            </div>
                        </div>

                        <div className="visual-slide-text">
                            <div className="inner">
                                <div className="con">
                                    <div className="text-wrap color1">
                                        <p className="s-text typo1">ABOUT GROC CHAMPIONSHIP FINAL</p>
                                        <p className="m-text">{t('Groc_top_class1')} <br/> {t('Groc_top_class2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-con-wrap">
                    <div className="inner">		
                        <div className="list list-type7">
                            <div className="list-con">
                                <p className="lg-ttl color2">{t('Groc_groc_title')}</p>
                                <div className="txt-wrap">
                                    <div className="txt">
                                        {t('Groc_groc_info_1')}<br/>
                                        {t('Groc_groc_info_2')}
                                    </div>
                                </div>

                                <div className="txt-table">
                                    <ul>
                                        <li className="item">
                                            <div className="ttl">{t('Groc_groc_info_5')}</div>
                                            <div className="txt-wrap">
                                                <div className="txt">
                                                    {t('Groc_groc_info_5_1')} <br/>
                                                    {t('Groc_groc_info_5_2')}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="groc-season-info">
                                    <div className="table-info red-table-info">
                                        <ul>
                                            <li>
                                                <div className="row-table">
                                                    <div className="item">
                                                        <div className="ttl"><span
                                                            className="typo2">{t('Groc_individual_1')}</span> {t('Groc_individual_2')}
                                                        </div>
                                                        <div className="txt-wrap">
                                                            <div className="txt">
                                                                {t('Groc_season_individual_6')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="ttl"><span
                                                            className="typo2">{t('Groc_team_1')}</span> {t('Groc_team_2')}
                                                        </div>
                                                        <div className="txt-wrap">
                                                            <div className="txt">
                                                                {t('Groc_season_team_6')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="txt-table">
                                    <ul>
                                        <li className="item">
                                        <div className="ttl">{t('Groc_groc_info_6')}</div>
                                            <div className="txt-wrap">
                                                <div className="txt">
                                                    {t('Groc_groc_info_6_1')} <br/>
                                                    {t('Groc_groc_info_6_2')} <br/><br/>
                                                    {t('Groc_groc_info_6_3')} <br/>
                                                    {t('Groc_groc_info_6_4')}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="groc-season-info">
                                    <div className="table-info">
                                        <ul>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">GROC SEASON</div>
                                                    <div className="txt">
                                                        {t('Groc_season_1')} <br/>
                                                        {t('Groc_season_2')}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">GROC Championship Final</div>
                                                    <div className="txt">
                                                        {t('Groc_season_final_1')} <br/>
                                                        {t('Groc_season_final_2')}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="groc-season-table">
                                    <p className="lg-ttl">{t('Groc_season_info')}</p>

                                    <div className="table-info table-info-style1">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">GROC 2025 Championship Final</span><span
                                                className="sm-txt">12.07</span></span>
                                        </div>
                                    </div>

                                    <div className="table-info">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">SEASON 2025</span><span
                                                className="sm-txt">SINGLES</span></span>
                                        </div>
                                        <ul className="table-list table-list3">
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 1</div>
                                                    <div className="date typo2">01.06 ~ 02.02</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 2</div>
                                                    <div className="date typo2">02.03 ~ 03.02</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 3</div>
                                                    <div className="date typo2">03.03 ~ 03.30</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 4</div>
                                                    <div className="date typo2">03.31 ~ 04.27</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 5</div>
                                                    <div className="date typo2">04.28 ~ 05.25</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 6</div>
                                                    <div className="date typo2">05.26 ~ 06.22</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 7</div>
                                                    <div className="date typo2">06.23 ~ 07.20</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 8</div>
                                                    <div className="date typo2">07.21 ~ 08.17</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 9</div>
                                                    <div className="date typo2">08.18 ~ 09.14</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 10</div>
                                                    <div className="date typo2">09.15 ~ 10.12</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">ROUND 11</div>
                                                    <div className="date typo2">10.13 ~ 11.09</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">WEEK</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2 center">POST ROUND</div>
                                                </div>
                                                <div className="row-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className="rowspan">11.10 ~ 01.04</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="table-info table-info-style2">
                                        <div className="table-info-ttl">
                                            <span className="ttl typo2"><span className="lg-txt">SEASON 2025</span><span
                                                className="sm-txt">TEAMS</span></span>
                                        </div>
                                        <ul className="table-list table-list1">
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">COMING SOON</div>
                                                </div>
                                            </li>
                                        </ul>
                                        {/*<ul className="table-list table-list2">
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">FINAL FOUR TEAM</div>
                                                    <div className="date typo2">12.01</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="ttl typo2">TEAM EVENT</div>
                                                    <div className="date typo2">01.01 ~ 11.03</div>
                                                </div>
                                            </li>
                                        </ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="list list-type6">
                            <div className="list-con">
                                <p className="lg-ttl">{t('Groc_season_member')}</p>
                            </div>

                            <div className="tab-wrap">
                                <ul className="tab-menu tab-menu-2">
                                    <li><button style={{cursor: "pointer"}} onClick={this.changeTab("individual")}
                                           className={(this.state.tab === "individual" ? "selected" : "")}>{t('Groc_individual')}</button>
                                    </li>
                                    <li><button style={{cursor: "pointer"}} onClick={this.changeTab("team")}
                                           className={(this.state.tab === "team" ? "selected" : "")}>{t('Groc_team')}</button>
                                    </li>
                                </ul>

                                <div className="tab-con-wrap">
                                    <div className="tab-con"
                                         style={{display: (this.state.tab === "individual" ? "block" : "none")}}>
                                        <div className="con">
                                            <div className="img">
                                                <img
                                                    src="https://asset.981park.com/www/common/web/img/sub/groc/groc-tab1.png"
                                                    alt="9.81 파크"/>
                                            </div>
                                        </div>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                {t('Groc_individual_sub_3_1')} <br/>
                                                {t('Groc_individual_sub_3_2')} <br/>
                                                {t('Groc_individual_sub_3_3')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-con"
                                         style={{display: (this.state.tab === "team" ? "block" : "none")}}>
                                        <div className="con">
                                            <div className="img">
                                                <img
                                                    src="https://asset.981park.com/www/common/web/img/sub/groc/groc-tab2.png"
                                                    alt="9.81 파크"/>
                                            </div>
                                        </div>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                {t('Groc_team_sub_4_1')} <br/>
                                                {t('Groc_team_sub_4_2')} <br/>
                                                {t('Groc_team_sub_4_3')} <br/>
                                                {t('Groc_team_sub_4_4')} <br/>
                                                {t('Groc_team_sub_4_5')} <br/>
                                                {t('Groc_team_sub_4_6')}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="txt ps-txt">
                                    <span>{t('Groc_sub_1')}</span>
                                    <span>{t('Groc_sub_2')}</span>
                                    <span>{t('Groc_sub_3')}</span>
                                    <span>{t('Groc_sub_4')}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AboutGROC);