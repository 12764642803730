export default {
  MMS: "MMS",
  PROMOSCOOT: "promoscoot",
  HOME: "",
  BOARD: "board",
  PARK: "park",
  ACTIVITY: "activity",
  PARKFLEX: "parkflex",
  CONVENIENT: "convenient",
  SHUTTLE: "shuttle",
  OPERATION: "operation",
  PLAY: "play",
  OUTRACE981: "outrace981",
  OUTHEAL: "outheal",
  INDOOR: "indoor",
  INARENA: "inarena",
  INPROARENA: "inproarena",
  INMETA: "inmeta",
  INRINGGO: "inringgo",
  INLAB: "inlab",
  INCUBE: "incube",
  PLAYFLEX: "playflex",
  FLGARAGE: "flgarage",
  FLPHOTO: "flphoto",
  FLSPACE: "flspace",
  FLBRO: "flbro",
  FLBOOSTER: "flbooster",
  FLONATZ: "flonatz",
  FLLOUNGE: "fllounge",
  RACE981: "race981",
  GRAVITYRACER: "gravityracer",
  LICENSE: "license",
  BATTLE: "battle",
  RANKING: "ranking",
  RANKINGRULE: "rankingrule",
  GROC: "groc",
  ABOUTGROC: "aboutgroc",
  HALLOFFAME: "halloffame",
  TICKET: "ticket",
  GROUP: "group",
  TEAMROOM: "teamroom",
  PRIVACY: "privacy",
  PRIVACY20190322: "privacy20190322",
  PRIVACY20190328: "privacy20190328",
  PRIVACY20200117: "privacy20200117",
  PRIVACY20200327: "privacy20200327",
  TERMS: "terms",
  RULES: "rules",
  GUIDE: "guide",
  Notice: "race981-important-notice",
  Lab981: "lab981",
  ParkAbout: "park-about"
};