import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";

class MTeamRoomComp  extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectTap: "mice",
		};
	}

	componentDidMount() {
    }

	changeTap = type => () => {
		this.setState({selectTap: type});
	}

	render() {
		const {t} = this.props;
		let rLink = "https://docs.google.com/forms/d/e/1FAIpQLSdytO-oLRpFeMrLTQ_XGfo3Ea1faOzXRaV25_cOZE1o0E-CEQ/viewform?usp=sf_link"
		if (i18next.language === "en" ) {
			rLink = "https://docs.google.com/forms/d/e/1FAIpQLSeMnEp8ZXUSvK7EVHHj5pAc4Q3rRoZ18U09tbFc0tDvKTwfvw/viewform"
		}
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/slide-group-reservation1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">MICE RESERVATION</p>
									<p className="m-text">
										{t('Ticket_sub_c9')} <br/>
										{t('Ticket_sub_c9_1')}
									</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type6">
							<div className="tab-wrap">
								<ul className="tab-menu tab-menu-4">
									<li><a onClick={this.changeTap("mice")} className={((this.state.selectTap === "mice") ? "selected" : "")} style={{cursor: "pointer"}}>{t('Team_sub_2')}</a></li>
									<li><a onClick={this.changeTap("team")} className={((this.state.selectTap === "team") ? "selected" : "")} style={{cursor: "pointer"}}>{t('Team_sub_1')}</a></li>
									<li><a onClick={this.changeTap("resv")} className={((this.state.selectTap === "resv") ? "selected" : "")} style={{cursor: "pointer"}}>{t('Team_sub_3')}</a></li>
									<li><a onClick={this.changeTap("seminar")} className={((this.state.selectTap === "seminar") ? "selected" : "")} style={{cursor: "pointer"}}>{t('Team_sub_4')}</a></li>
								</ul>

								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.selectTap === "team") ? "block" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/img-group-program-tab1.png" alt="9.81 파크"/>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">
												<p className="ttl">{t('Team_sub_1_1')} <br/> {t('Team_sub_1_1_1')}</p>
												<ul>
													<li>
														<p className="txt">
															<span >{t('Team_sub_1_2')}</span><br/>
															<span >{t('Team_sub_1_3')}</span><br/>
															<span >{t('Team_sub_1_4')}</span><br/>
															<span className="ps-txt pl-10">{t('Team_sub_1_5')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div className="tab-con" style={{display: (this.state.selectTap === "mice") ? "block" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/img-group-program-tab2.png" alt="9.81 파크"/>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">
												<p className="ttl">{t('Team_sub_2_1')} <br/> {t('Team_sub_2_1_1')}</p>
												<ul>
													<li>
														<p className="txt">
															{t('Team_sub_2_2')}<br/>
															{t('Team_sub_2_2_1')}<br/>
															{t('Team_sub_2_3')}<br/>
															{t('Team_sub_2_3_1')}<br/>
															{t('Team_sub_2_4')}<br/>
															{t('Team_sub_2_4_1')}<br/>
															<span className="ps-txt">{t('Team_sub_2_5')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div className="tab-con" style={{display: (this.state.selectTap === "resv") ? "block" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/img-group-program-tab3.png" alt="9.81 파크"/>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">
												<p className="ttl">{t('Team_sub_3_1')} <br/> {t('Team_sub_3_1_1')}</p>
												<ul>
													<li>
														<p className="txt">
															<span >{t('Team_sub_3_2')}</span><br/>
															<span >{t('Team_sub_3_3')}</span><br/>
															<span >{t('Team_sub_3_4')}</span><br/>
															<span >{t('Team_sub_3_5')}</span><br/>
															<span className="ps-txt pl-10">{t('Team_sub_3_6')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div className="tab-con" style={{display: (this.state.selectTap === "seminar") ? "block" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/img-group-program-tab4.png" alt="9.81 파크"/>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">
												<p className="ttl">{t('Team_sub_4_1')}</p>
												<ul>
													<li>
														<p className="txt">
															<span >{t('Team_sub_4_2')}</span><br/>
															<span >{t('Team_sub_4_3')}</span><br/>
															<span >{t('Team_sub_4_4')}</span><br/>
															<span >{t('Team_sub_4_5')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="list list-type9">
							<div className="list-con">
								<p className="lg-ttl">{t('Team_title_c1')}</p>
								<div className="list-txt">
									<ul>
										<li>
											<p className="txt">
												{t('Team_title_c2')} <br/>
												<span className="hyphen">{t('Team_title_c2_1')} <a href={rLink} target="_blank" className="link">{t('Team_title_c2_2')}</a> {t('Team_title_c2_3')}</span> <br/>
												<span className="hyphen">{t('Team_title_c2_4')}</span>
											</p>
										</li>
										<li>
											<p className="txt">
												{t('Team_title_c3')} <br/>
												<span className="hyphen">{t('Team_title_c3_1')}</span> <br/>
												<span className="hyphen">{t('Team_title_c3_2')}</span> <br/>
												<span className="hyphen">{t('Team_title_c3_3')}</span> <br/>
												<span className="hyphen">{t('Team_title_c3_4')}</span> <br/>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
		);
	}
}
export default withTranslation()(MTeamRoomComp);