import React from "react";
import {withTranslation} from "react-i18next";

class BattleComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			tap: "Custom"
		};
	}

	selectTap = course => () => {
        switch (course) {
            case "Custom":
				if (this.state.tap !== "Custom") {
					this.setState({tap: "Custom"})
				}
                break;
            case "Live":
                if (this.state.tap !== "Live") {
					this.setState({tap: "Live"})
				}
                break;
			default :
				if (this.state.tap !== "Custom") {
					this.setState({tap: "Custom"})
				}
        }        
	};

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/race/slide-bettle1.jpg" alt="레이스 981을 게임처럼 즐겨보자!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">BATTLE</p>
									<p className="m-text">{t('GR_title3')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type5">
							<div className="list-con">
								<p className="lg-ttl">{t('Battle_sub_title')}</p>
								<div className="txt-wrap">
									<div className="lg-txt">
										{t('Battle_sub_c1')} {t('Battle_sub_c1_1')}
									</div>
								</div>
							</div>	
						</div>
						<div className="list list-type6">
							<div className="tab-wrap">
								<ul className="tab-menu tab-menu-2">
									<li><a onClick={this.selectTap("Custom")} className={(this.state.tap === "Custom") ? "selected" : ""} style={{cursor: "pointer"}}>{t('Battle_sub_tab1')}</a></li>
									<li><a onClick={this.selectTap("Live")} className={(this.state.tap === "Live") ? "selected" : ""} style={{cursor: "pointer"}}>{t('Battle_sub_tab2')}</a></li>
								</ul>

								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.tap === "Custom") ? "" : "none"}}>
										<div className="list-con">
											<p className="txt">{t('Battle_sub_tab1_c1')}</p>
										</div>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/web/img/sub/race/bettle-tab1.png" alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Battle_sub_tab1_c2')}</p>					
												<ul>
													<li><p className="txt">{t('Battle_sub_tab1_c3')}</p></li>
													<li><p className="txt">{t('Battle_sub_tab1_c4')}</p></li>
													<li><p className="txt">{t('Battle_sub_tab1_c5')} </p></li>
													<li>
														<p className="txt">
															{t('Battle_sub_tab1_c6')} <br/>
															{t('Battle_sub_tab1_c7')}
														</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Battle_sub_tab1_c8')}</p>					
												<ul>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-ready.svg" alt="준비중" /></span>
															<span>{t('Battle_sub_tab1_c10')}</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-play.svg" alt="진행중" /></span>
															<span>{t('Battle_sub_tab1_c12')}</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-finished.svg" alt="종료" /></span>
															<span>{t('Battle_sub_tab1_c14')} </span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-canceled.svg" alt="무효" /></span>
															<span>{t('Battle_sub_tab1_c16')}</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span>{t('Battle_sub_tab1_c17')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
									
									<div className="tab-con" style={{display: (this.state.tap === "Live") ? "" : "none"}}>
										<div className="list-con">
											<p className="txt">
												{t('Battle_sub_tab2_c1')} {t('Battle_sub_tab2_c1_1')}
											</p>
										</div>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/web/img/sub/race/bettle-tab2.png" alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Battle_sub_tab2_c2')}</p>					
												<ul>
													<li><p className="txt">{t('Battle_sub_tab2_c3')}</p></li>
													<li><p className="txt">{t('Battle_sub_tab2_c4')}</p></li>
													<li><p className="txt">{t('Battle_sub_tab2_c4_1')}</p></li>
													<li>
														<p className="txt">
															{t('Battle_sub_tab2_c5')} <br/>
															{t('Battle_sub_tab2_c5_1')}
														</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Battle_sub_tab1_c8_1')}</p>					
												<ul>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-ready.svg" alt="준비중" /></span>
															<span>{t('Battle_sub_tab2_c7')}</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-play.svg" alt="진행중" /></span>
															<span>
																{t('Battle_sub_tab2_c9')}
																{/*{t('Battle_sub_tab2_c10')}*/}
															</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span className="label"><img src="https://asset.981park.com/www/common/web/img/sub/race/img-battle-state-finished.svg" alt="종료" /></span>
															<span>{t('Battle_sub_tab2_c12')}</span>
														</p>
													</li>
													<li>
														<p className="txt">
															<span>{t('Battle_sub_tab2_c13')}</span>
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(BattleComp);