import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import { Markup } from "interweave"
class MGravityRacerComp  extends React.Component {
	constructor(props) {
        super(props);
		this.tabX = React.createRef();
		this.state = {
			tap: "E"
		};
	}

	componentDidMount() {
		this.scrollTab();
	}

    scrollTab() {
		if (window.location.hash !== "") {
			let hash = window.location.hash.replace("#", "")
			let hashResult = hash.split('?')[0]
            if (hashResult === "tabX") {
                this.tabX.current.scrollIntoView();
				this.setState({tap: "X"})
            }
		} else {
			window.scrollTo(0, 0);
		}
	}

	handleImageLoaded() {
		this.scrollTab();
	}

	selectTap = course => () => {
        switch (course) {
            case "E":
				if (this.state.tap !== "E") {
					this.setState({tap: "E"})
				}
                break;
            case "D":
                if (this.state.tap !== "D") {
					this.setState({tap: "D"})
				}
                break;
            case "X":
                if (this.state.tap !== "X") {
					this.setState({tap: "X"})
				}
                break;
			default :
				if (this.state.tap !== "E") {
					this.setState({tap: "E"})
				}
        }        
	};

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/race/slide-gravity-racer1.png" onLoad={this.handleImageLoaded.bind(this)} alt="레이스 981을 게임처럼 즐겨보자!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">GRAVITY RACER</p>
									<p className="m-text">{t('GR_title1')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						
						<div className="list list-type5">
							<div className="list-con">
								<p className="lg-ttl">{t('GR_sub_title')}</p>
								<div className="txt-wrap">
									<div className="lg-txt">
										{t('GR_sub_c')}
										<br ref={this.tabX}/>
										<br/>
									</div>
								</div>
							</div>
						</div>
						<div className="list list-type6">
							<div className="tab-wrap">
								<ul className="tab-menu tab-menu-3">
									<li><a onClick={this.selectTap("E")} className={(this.state.tap === "E") ? "selected" : ""}>GR-E <br/> <span className="s-txt">(Course 1,2)</span></a></li>
									<li><a onClick={this.selectTap("D")} className={(this.state.tap === "D") ? "selected" : ""} >GR-D <br/> <span className="s-txt">(Course 3)</span></a></li>
									<li><a onClick={this.selectTap("X")} className={(this.state.tap === "X") ? "selected" : ""} >GR-X <br/> <span className="s-txt">(Course X)</span></a></li>
								</ul>

								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.tap === "E") ? "" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/race/gravity-racer-tab1.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('GR_e_title')}</p>					
												<ul>
													<li>
														<p className="txt">
															{t('GR_e_contents_1')}
															{t('GR_e_contents_1_1')}
														</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt">						
												<ul>
													<li><p className="txt">{t('GR_e_contents_2')}</p></li>
													<li><p className="txt">{t('GR_e_contents_3')}</p></li>
													<li><p className="txt">{t('GR_e_contents_4')}</p></li>
													<li><p className="txt">{t('GR_e_contents_5')}</p></li>
													<li><p className="txt">{t('GR_e_contents_6')}</p></li>
												</ul>
											</div>
										</div>	
									</div>
									
									<div className="tab-con" style={{display: (this.state.tap === "D") ? "" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/race/gravity-racer-tab2.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('GR_d_title')}</p>					
												<ul>
													<li>
														<p className="txt">
															{t('GR_d_contents_1')}
														</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt">						
												<ul>
													<li><p className="txt">{t('GR_d_contents_2')}</p></li>
													<li>
														<p className="txt">
															{t('GR_d_contents_3')}<br/>
															<span className="indent"><Markup content={t('GR_d_contents_3_1')}/></span><br/>
															<span className="indent"><Markup content={t('GR_d_contents_4')}/></span>
														</p>
													</li>
													<li><p className="txt">{t('GR_d_contents_5')}</p></li>
													<li><p className="txt text-indent-38">{t('GR_d_contents_6')}</p></li>
													<li><p className="txt">{t('GR_d_contents_8')}</p></li>
												</ul>
												
												<p className="txt ps-txt">{t('GR_d_contents_7')}</p>
											</div>
										</div>	
									</div>
									
									<div className="tab-con" style={{display: (this.state.tap === "X") ? "" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/race/gravity-racer-tab3.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('GR_x_title')}</p>
												<ul>
													<li>
														<p className="txt">
															{t('GR_x_contents_1')}
														</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt">						
												<ul>
													<li><p className="txt text-indent-64">{t('GR_x_contents_2')} <br/> {t('GR_x_contents_2_1')}</p></li>
													<li><p className="txt text-indent-64">{t('GR_x_contents_3')} <br/> {t('GR_x_contents_3_1')}</p></li>
													<li><p className="txt">{t('GR_x_contents_4')}</p></li>
													<li><p className="txt text-indent-38">{t('GR_x_contents_5')}</p></li>
													<li>
														<p className="txt">
															<span>{t('GR_x_contents_6')}</span> <br/>
															<span className="indent">{t('GR_x_contents_6_1')}</span> <br/>
															<span className="indent">{t('GR_x_contents_6_2')}</span> <br/>
															<span className="indent">{t('GR_x_contents_6_3')}</span> <br/>
															<span className="txt ps-txt">{t('GR_x_contents_6_4')}</span> <br/>
															<span className="txt ps-txt">{t('GR_x_contents_6_5')}</span>
														</p>
													</li>
												</ul>									
											</div>
										</div>	
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MGravityRacerComp);