import React from 'react';
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'

class CnRace981GuideComp  extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            step: 1
		};
    }

    chageStep = step => () => {
        if (this.state.step !== step) {
            this.setState({step: step})
            window.scrollTo(0,0)
        }
	};

	render() {
        const {t} = this.props;
		return (
			<>
                <div className="content-wrap hearing-impaired-content-wrap race-981">
                    <div className="visual-wrap">
                        <div className="img">
                            <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/bitmap.png" alt="race981"/>
                        </div>
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap color1">
                                    <p className="s-text typo1">RACE 981</p>
                                    <p className="m-text">全身感受凉爽的风！</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-con-wrap">
                        <div className="tab-wrap">
                            <div className="tab-menu-wrap">
                                <ul className="tab-menu tab-menu-3">
                                    <li>
                                        <a onClick={this.chageStep(1)} className={this.state.step === 1 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">APP</span>
                                                <span className="txt">APP 使用指南</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={this.chageStep(2)} className={this.state.step === 2 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">B2</span>
                                                <span className="txt">中央广场・入口</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={this.chageStep(3)} className={this.state.step === 3 ? "selected" : ""}>
                                            <div className="txt-wrap">
                                                <span className="location">G</span>
                                                <span className="txt">乘坐场</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-con-wrap">
                                <div className="inner">
                                    <div className="tab-con" style={{display: (this.state.step === 1) ? "" : "none"}}>
                                        <div className="notice">
                                            <div className="notice-con">
                                                <p className="ttl">乘坐卡丁车前注意事项</p>
                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        <p>· 满 14岁以上能卡丁车</p>
                                                        <p>· 身高 150cm~190cm可用</p>
                                                        <p>· 同乘者乘坐后座时，司机是要满 19岁以上</p>
                                                        <p>· 不许穿高跟鞋、厚底</p>
                                                        <p>· 不许穿前后穿透的鞋子 <br/>
                                                            * 怎么解决？在 ‘GARAGE 981’ 要买保护套鞋</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">9.81Park APP使用指南</p>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-app.png" alt="981 PARK APP"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="notice-guide">
                                            <div className="notice-guide-con">
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 下载 APP后能知道如何登记门票， <br/> 然后要得看安全视频！</div>
                                                        <div className="txt">
                                                            请您扫以下门票的二维码后下载APP, <br/>
                                                            在 APP上登记门票, 您可以收到乘坐的视频和 <br/>
                                                            成绩！
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. 无论何时重要的是安全！</div>
                                                        <div className="txt">
                                                            麻烦您乘坐卡丁车前观看一下在 APP上 <br/>
                                                            的安全视频。
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. 好奇心由 APP来能回答</div>
                                                        <div className="txt">
                                                            <p>如果不太清楚我的门票信息，就在 APP上可<br/>
                                                            查信息, 您可以确认每个游乐设施的位置和<br/>
                                                            次数, 而赛车入场时间和赛道后乘坐！</p>
                                                            <p>赛道(路线)按照数字的顺序，<br/>
                                                                比如 : 3 > 1 > 2 按3-1-2的顺序, <br/>
                                                                赛道(路线) 1 > 1 > 2 按 1-1-2的顺序乘坐！</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 2) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">中央广场・入口</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-central.png" alt="센트럴"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        如果大屏幕上显示了我的预约的时间？ <br/>
                                                        就是现在！和同僚赛车手们依次准备入场！
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">工作人员的说明如下。</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 随身物品放在物品保管处！</div>
                                                        <div className="txt">
                                                            除手机以外的所有物品都不能携带乘坐，<br/>
                                                            请保管在物品保管处。
                                                        </div>
                                                        <div className="ps-txt">
                                                            免费物品保管处从紧急出口往左走就能找到。
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">赛场入口</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-gate.png" alt="게이트"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        入场之前会有工作人员的说明, 请您仔细听, <br/>
                                                        给工作人员看安全视频看过的记录。<br/>
                                                        (在981APP上有)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">工作人员的说明如下。</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 把收敛的门票扫到在入场</div>
                                                        <div className="txt">
                                                            票上的“9.81”部分内置了NFC芯片，<br/>
                                                            请把门票的“9.81”部分扫描在门禁箭头。<br/>
                                                            如果正是入场的时候入口会亮起白灯。
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. 通过入口时请注意！</div>
                                                        <div className="txt">
                                                            麻烦您先等到前面的人通过, 入口的白灯<br/>
                                                            关后扫您的门票。
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. 进入卡丁车前需要知道的 <br/> 2个事项！</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2"><b>1</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>速度安全提示</b></p>
                                                                    <p className="txt">
                                                                        GR(Race 981 车辆) 接近最高速度时 <br/>
                                                                        会发出“哔-哔-哔-”的声音警告，<br/>
                                                                        如果超过最高速度, 为了您的安全,自动 <br/>
                                                                        刹车将会启动。<br/>
                                                                        所以即使突然卡丁车变慢也不要担心, 	<br/>
                                                                        到最后也不要忘记一定要安全驾驶！</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2"><b>2</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>看到出现障碍物的话？</b></p>
                                                                    <p className="txt">
                                                                        如果路上有障碍物, 请不要惊慌，<br/>
                                                                        慢慢开车。<br/>
                                                                        如果障碍物原因不能好好享受赛车的<br/>
                                                                        话, 结束后一定要告诉工作人员！</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 3) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">乘坐场</p>
                                                    <div className="location">G</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-boarding.png" alt="탑승장"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        乘车及下车时, 请按照工作人员的提示进行。<br/>
                                                        专业的工作人员会让您的驾驶变得很流畅。
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">工作人员的说明如下。</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 请安全地上卡丁车！</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">把双脚放在卡丁车上, 然后坐下。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">请您扫一下把手链票扫到卡丁车屏幕 <br/>
                                                                        右侧的"9.81"部分。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">手机(主要随身物品)保管在口袋里。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">如果您用单人座椅将双肩上的安全带 <br/>
                                                                        交叉成X形后扣上, 双人座椅将双臂穿 <br/>
                                                                        入安全带后扣上。</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">2. 等一下! 如果乘坐3赛道GR-D的话, <br/>
                                                            就是获得呐喊助推器的机会！</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">9.81 Park APP里下面有 [ACTIVITY]。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">按上端的 Scream Booster 项目。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">按下 [Play] 后, 在卡丁车上扫门票！</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">手机安装在座位前面的支架上。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">如果手机屏幕上出现 [Ready], <br/>
                                                                        然后准备呐喊！</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">6</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">按照工作人员的引导, 在出发线前 <br/>
                                                                        等待后, 将脚从刹车上移开, <br/>
                                                                        手机屏幕就会改变, 游戏开始!</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">7</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">在限制时间 3秒内用力呐喊, 获得 <br/>
                                                                        GR-D的特别助推器吧！</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">3. 开始赛车前准备运动！</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">卡丁车没有油门, <br/>
                                                                        只用右脚前面的刹车调节速度。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">一直踩着刹车等待, 然后职员 <br/>
                                                                        会发出信号, 走到前面的黄线停住。</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-1.svg" alt="준비 운동1"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">将沉重的方向盘左右用力移动 <br/>
                                                                        (为了您适应), 在红绿灯前面停车。 <br/>
                                                                        (您要测试一下用方向盘能移动到 <br/>
                                                                        红绿灯）</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-2.svg" alt="준비 운동2"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">红绿灯变绿色就能出发。</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-3.svg" alt="준비 운동3"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">4. 赛车过程中必须遵守的介绍事项！</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">在行驶过程中, 如果使用手机, 故意停<br/>
                                                                    车, 碰撞以及双手离开方向盘, 将被失<br/>
                                                                    去资格。<br/>
                                                                    (APP中无法接收记录和视频。)</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">经过终点线(Finish)后, 直接用力踩刹车<br/>
                                                                    减速。<br/>
                                                                    *千万不要停车,!减速!,要移动到地面看<br/>
                                                                    到的 STOP区间然后停止。</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/safety.gif" alt="레이스 중 주의사항"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">沿着蓝线慢行到工作人员指引的位置<br/>
                                                                        然后完全停车。工作人员的引导下，<br/>
                                                                        用力踩刹车 3秒，然后松开脚。<br/>
                                                                        从这时开始车辆将自动驾驶模式。<br/>
                                                                        (自动驾驶中可以使用手机，<br/>
                                                                        可以拍照!）</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">在自动驾驶过程中，如果车辆有问题<br/>
                                                                    或认为与前面车太近, 可以自己踩刹车<br/>
                                                                    后举手。</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">下车时, 耐心等待车辆完全停车后, 解开<br/>
                                                                    安全带, 在工作人员的指导下下车。</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default withTranslation()(CnRace981GuideComp);