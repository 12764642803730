import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import Slider from "react-slick";

class MParkComp  extends React.Component {
	constructor(props) {
        super(props);
        this.ticket = React.createRef();
        this.map = React.createRef();
		this.state = {
		};
    }

    componentDidMount() {
		this.scrollMap();
	}

    scrollMap() {
        if (window.location.hash !== "") {
			let hash = window.location.hash.replace("#", "")
			let hashResult = hash.split('?')[0]
            if (hashResult === "map") {
                this.map.current.scrollIntoView();
            }
		} else {
			window.scrollTo(0, 0);
		}
	}

    handleImageLoaded() {
		this.scrollMap();
	}

    scrollContent = () => () => {
        this.ticket.current.scrollIntoView({ behavior: 'smooth'});
    };
    
    openMap = type => () => {
        switch (type) {
            case "naver":
                window.open('https://map.naver.com/v5/entry/place/1666616962?c=14067054.8006824,3947189.6930496,15,0,0,0,dh')
                break;
            case "kakao":
                window.open('https://map.kakao.com/?urlX=353062&urlY=-28200&urlLevel=4&map_type=TYPE_MAP&map_hybrid=false')
                break;
            case "google":
                window.open('https://www.google.com/maps/place/9.81+%ED%8C%8C%ED%81%AC/@33.3911825,126.3665285,15z/data=!4m2!3m1!1s0x0:0xb1b8a666bc10abe1?sa=X&ved=2ahUKEwjytIDB5YvtAhXay4sBHcj8A7IQ_BIwD3oECBoQBQ')
                break;
        }
    }

	render() {
        const {t} = this.props;

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 5
        };
		return (
			<div className="content-wrap sub-content">
                <div className="visual-wrap">
                    <div className="img">
                        <img src="https://asset.981park.com/www/common/mweb/img/sub/park/park-bg1.jpg" onLoad={this.handleImageLoaded.bind(this)} alt="애월 바다와 한라산 사이의 스마트 놀이+터, 9.81 파크"/>
                    </div>
                    <div className="inner">
                        <div className="con">
                            <div className="text-wrap color1">
                                <p className="s-text typo1">PARK  INFORMATION</p>
                                <p className="m-text">{t('ParkComp_title_1')} <br/> {t('ParkComp_title_2')} <br/>{t('ParkComp_title_3')}</p>
                            </div>
                        </div>	
                    </div>
                </div>
                <div className="card-list-wrap">
                    <div className="card-wrap">
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap">
                                    <p className="s-text typo1">SPACE INFORMATION</p>
                                    <p className="m-text">{t('ParkComp_c_title_1')} <br/> {t('ParkComp_c_title_2')}</p>
                                </div>
                                
                                <div className="card-list">
                                    <div className="card">
                                        <div className="card-inner">
                                            <img src="https://asset.981park.com/www/common/mweb/img/sub/park/card-1-1.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크" className="img img-1"/>
                                            <p className="text typo1 color1">ACTIVITY <br/> SPACE</p>
                                            <div className="label-txt"><span>{t('ParkComp_c_activity_title')}</span></div>
                                            <div className="button-wrap">
                                                <Link to={`/`+RoutePath.ACTIVITY} className="link-more"><span className="blind">more</span></Link>
                                            </div>
                                        </div>						
                                    </div>
                                    <div className="card">
                                        <div className="card-inner">
                                            <img src="https://asset.981park.com/www/common/mweb/img/sub/park/card-1-2.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크" className="img img-1"/>
                                            <p className="text typo1 color1"> FLEX &amp; RELAX <br/> SPACE</p>
                                            <div className="label-txt"><span>{t('ParkComp_c_flex_title')}</span></div>
                                            <div className="button-wrap">
                                                <Link to={`/`+RoutePath.PARKFLEX} className="link-more"><span className="blind">more</span></Link>
                                            </div>
                                        </div>					
                                    </div>
                                    <div className="card">
                                        <div className="card-inner">
                                            <img src="https://asset.981park.com/www/common/mweb/img/sub/park/card-1-3.png" alt="9.81 파크" onLoad={this.handleImageLoaded.bind(this)} className="img img-1"/>
                                            <p className="text typo1 color1">CONVENIENT <br/> FACILITIES</p>
                                            <div className="label-txt"><span>{t('ParkComp_c_convenient_title')}</span></div>
                                            <div className="button-wrap">
                                                <Link to={`/`+RoutePath.CONVENIENT} className="link-more"><span className="blind">more</span></Link>
                                            </div>
                                        </div>					
                                    </div>
                                    <div ref={this.map} />
                                </div>
                            </div>
                        </div>					
                    </div>
                </div>
                <div className="map-wrap">
                    <div className="map">
                        <img src="https://asset.981park.com/www/common/mweb/img/sub/park/map.jpg" onLoad={this.handleImageLoaded.bind(this)} alt="제주시 애월읍 천덕로 880-24 , 9.81 파크"/>
                    </div>
                    <div className="inner">
                        <div className="con">
                            <div className="text-wrap color1">
                                <p className="s-text typo1">DIRECTIONS</p>
                                <p className="m-text">{t('ParkComp_addr_1')} <br/> {t('ParkComp_addr_2')}</p>

                                <div className="map-button-pc">
                                    <button type="button" onClick={this.openMap("naver")} className="btn btn-naver"><span className="blind">{t('NaverMap')}</span></button>
                                    <button type="button" onClick={this.openMap("kakao")} className="btn btn-kakao"><span className="blind">{t('KakaoMap')}</span></button>
                                    <button type="button" onClick={this.openMap("google")} className="btn btn-google"><span className="blind">{t('GoogleMap')}</span></button>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>		
                <div className="ticket-list-wrap m-ticket-slider">
                    <div className="inner">
                        <div className="ticket-wrap">
                            <div className="ticket-list">	
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    <div className="ticket t-case4">
                                        <div className="ticket-inner">
                                            <div className="label"><span className="blind">{t('ParkComp_card_1_title')}</span></div>
                                            <Link to={`/`+RoutePath.SHUTTLE} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text typo1">SHUTTLE <br/> BUS</p>	
                                        </div>							
                                    </div>
                                    <div className="ticket t-case5">
                                        <div className="ticket-inner">
                                            <div className="label"><span className="blind">{t('ParkComp_card_2_title')}</span></div>
                                            <Link to={`/`+RoutePath.OPERATION} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text typo1">OPERATING <br/> HOURS</p>	
                                        </div>							
                                    </div>	
                                </Slider>
                            </div>
                        </div>
                    </div>				
                </div>			
            </div>
		);
	}
}
export default withTranslation()(MParkComp);