import React from 'react';

class MMSComponents  extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('../common/mweb/css/index.css');
    }

    render() {
        return (
            <div className="park-wrap mms-groc-wrap">
                <div className="content-wrap">
                    <div className="contents">
                        <div className="info">
                            <div className="img-wrap">
                                <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/img-1.png" alt=""/></div>
                            </div>
                        </div>

                        <div className="info">
                            <div className="txt-wrap wh-area">
                                <div className="inner">
                                    <p className="ttl typo1">GROC 2024 Championship Final</p>
                                    <div className="txt">
                                        <p>GROC 챔피언십 파이널은 오직 중력가속도</p>
                                        <p>(g=9.81m/s²)만으로 레이스를 펼치는 전 세계 유일</p>
                                        <p>그래비티 레이싱(Gravity Racing) 대회입니다.</p><br/>

                                        <p>GROC 챔피언십 파이널에 초청되신 여러분은</p>
                                        <p>1년간 진행된 ‘정규 라운드 Top 10 (Singles)’ 또는</p>
                                        <p>‘시즌 4강(Teams)’ 진출에 성공한 마스터 레이서입니다.</p><br/>

                                        <p>현재까지 <b>총 10,796명</b>의 마스터 레이서가</p>
                                        <p>챔피언십 파이널에 도전했고, 그중 여러분은</p>
                                        <p><b>0.926%</b>의 확률을 뚫고 진출 자격을 확보했습니다.</p><br/>

                                        <p>특히, 올해는 새로운 상금 제도를 도입해</p>
                                        <p><b>총 상금 4,000만원</b>을 대회 당일 결정된 순위에 따라</p>
                                        <p>참가한 <b>모든 Finalist에게 차등 지급</b>할 예정이오니</p>
                                        <p>꼭 참석하시어 자리를 빛내 주시기 바랍니다.</p>

                                    </div>
                                    <div className="btn-area">
                                        <a href="https://youtu.be/2v-u5FsOvNI?si=ExbTuCohLeRDj51_" target="_blank" rel="noopener noreferrer"
                                           className="btn"><span className="txt">전년도 대회 영상 보러가기 ></span></a>
                                    </div>
                                </div>
                                <div className="img-wrap">
                                    <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/img-2.svg" alt=""/></div>
                                </div>
                            </div>
                        </div>

                        <div className="info">
                            <div className="inner">
                                <div className="txt-wrap">
                                    <p className="ttl">대회 안내</p>
                                    <div className="txt">
                                        <p><b>일시</b> <span className="bar">|</span> 2024년 12월 1일 (일), 09:00 ~ 16:30</p>
                                        <p><b>장소</b> <span className="bar">|</span> 9.81파크 제주</p>
                                        <p className="text-indent-63"><b>경기 방식</b> <span
                                            className="bar">|</span> SINGLES(개인전) 1, 2차 주행</p>
                                        <p className="pl-70">TEAMS(팀전) 준결승, 결승</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">참가 신청</p>
                                    <div className="txt">
                                        <p>2024년 11월 8일(금) 오후 6시까지</p>
                                    </div>
                                    {/*<div className="btn-area">
                                        <a href="https://forms.gle/zA2utdZnoqsdL1Zo8" target="_blank" rel="noopener noreferrer" className="btn btn-pt-1"><span className="txt">대회 참가 신청하기 ></span></a>
                                    </div>*/}
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">대회 일정</p>
                                    <div className="time-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th><span className="blind">내용없음</span></th>
                                                    <td>
                                                        <p className="date">2024년 12월 1일 (일)</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">9:00</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">리셉션 오픈</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">10:20</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">Finalist & Teams 등록 <br/> 및 물품 보관 마감</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">10:30</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">개회식 및 선수 선언식</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">10:50</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">
                                                            레드카펫 세리머니, 오피셜 포토 촬영<br/>
                                                            <span className="ps-txt">팀전 참가 인원 우선 진행 <br/> 이후 개인전 조별 순서로 진행</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">11:00</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">TEAMS 준결승</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">11:30</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">SINGLES 1차 주행: Finalist 전원</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">11:30 ~ 13:30</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">점심시간</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">14:00</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">SINGLES 2차 주행: 1차 11 ~ 50위</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">15:00</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">TEAMS 결승</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">15:10</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">SINGLES 2차 주행: 1차 1 ~ 10위</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">15:40</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">시상식 및 폐회식</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="time">16:30</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">대회 종료 및 상금 수령</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="inner wh-area">
                                <div className="txt-wrap">
                                    <p className="ttl lg-ttl">총 상금</p>
                                    <div className="img-wrap">
                                        <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/ttl-3.svg" alt=""/></div>
                                    </div>
                                    <div className="wh-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="txt">SINGLES</span>
                                                    </th>
                                                    <th>
                                                        <span className="txt">TEAMS</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="txt-wrap">
                                                            <p className="txt">1등 : 300만원</p>
                                                            <p className="txt pl-30">+ Champion 자켓</p>
                                                            <p className="txt pl-30">+ 골든 플레이트</p>
                                                            <p className="txt">2등 : 150만원</p>
                                                            <p className="txt">3등 : 100만원</p>
                                                            <p className="txt">4등 부터 순위에 따라</p>
                                                            <p className="txt">80만원~10만원까지</p>
                                                            <p className="txt">차등 지급</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="txt-wrap">
                                                            <p className="txt">1등 : 150만원</p>
                                                            <p className="txt">2등 : 100만원</p>
                                                            <p className="txt">공동 3등 : 60만원</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="ps-txt">
                                        <p>*총 상금은 Singles 와 Teams 지급 합산 금액입니다.</p>
                                        <p>*상금은 시상식 종료 후 본인 서명하에 지급되오니<br/>
                                            마지막까지 자리 지켜주시기를 바랍니다.</p>
                                        <p>*상금은 제세공과금을 제외 후 지급됩니다.</p>
                                    </div>
                                </div>

                                <p className="ttl box-ttl">프로그램 및 혜택 안내</p>

                                <div className="txt-wrap">
                                    <p className="ttl">리셉션 & 선수 등록 (B1F)</p>

                                    <div className="txt">
                                        <p>대회 당일 선수 등록을 위해 9.81파크 앱이 설치된<br/>
                                            본인 명의의 휴대폰과 닉네임을 확인합니다.</p>
                                        <p>선수 등록 완료 시 아래 혜택을 받을 수 있습니다.</p>
                                    </div>
                                    <div className="img-wrap">
                                        <div className="label"><span className="txt">B1F 출입구</span></div>
                                        <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/map-1.svg" alt=""/></div>
                                    </div>
                                    <div className="dt-txt">
                                        <p>리셉션 수령 : 네임 카드, 선수 전용 티켓,<br/>
                                            동반 1인 전용 티켓</p>
                                        <p>앱 쿠폰 수령 : 레이스981 1회 50% 할인쿠폰 10매,<br/>
                                            점심 식사 쿠폰 (동반자 1인 포함)</p>
                                        <p>입장 시 혼잡이 예상되오니, 가급적 오전 9시 도착으로<br/>
                                            부탁드립니다.</p>
                                        <p>선수 등록 마감시간(오전 10시 20분)까지 방문하지<br/>
                                            않으시면 대회 참가가 불가하오니 마감 시간 전까지<br/>
                                            리셉션(B1F)에서 등록하시길 바랍니다.<br/>
                                            *신분증 지참 필수</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">웰컴패키지 & 선수 전용 물품보관함</p>

                                    <div className="txt">
                                        <p>참가 경기에 따라 SINGLES, TEAMS 각각의 패키지 지급</p>
                                        <p>선수 전용 물품 보관함(B2F)에서 수령</p>
                                    </div>
                                    <div className="img-wrap">
                                        <div className="label"><span className="txt">B2F 센트럴</span></div>
                                        <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/map-2.svg" alt=""/></div>
                                    </div>
                                    <div className="dt-txt">
                                        <p>SINGLES 웰컴 패키지 :<br/>
                                            개인의 닉네임이 각인된 Singles 전용 아이템</p>
                                        <p>TEAMS 웰컴 패키지 :<br/>
                                            팀의 대표 컬러를 담은 Teams 전용 아이템</p>
                                        <p>개인 소지품은 전용 물품보관함에 보관하고<br/>
                                            네임 카드를 반드시 착용해 주시기 바랍니다.</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">개회식 & 전체 선수 선언식</p>
                                    <div className="txt">
                                        <p>개회식 및 전체 선수 선언식, 단체 사진 촬영 참석 필수</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">레드카펫 & 오피셜 포토</p>
                                    <div className="txt">
                                        <p>패독 입장 전, 직접 선정한 BGM에 맞춰 레드카펫<br/>
                                            세리머니 진행</p>
                                        <p>패독 입장 후, 오피셜 포토 촬영 진행</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">점심 식사</p>
                                    <div className="txt">
                                        <p><b>시간</b> <span className="bar">|</span> 11:30 ~ 13:30 (조별 식사 시간 맞춰 이용)</p>
                                        <p><b>위치</b> <span className="bar">|</span> 단체식당 (입구 옆 하늘그네 방향 계단 이용)</p>
                                    </div>
                                    <div className="dt-txt">
                                        <p>조별 식사 시간</p>
                                    </div>
                                    <div className="wh-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="ttl">A / B / C 조</span>
                                                        <span className="txt">11:00 ~</span>
                                                    </td>
                                                    <td>
                                                        <span className="ttl">D / E / F 조</span>
                                                        <span className="txt">12:00 ~</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ttl">G / H / I 조</span>
                                                        <span className="txt">12:30 ~</span>
                                                    </td>
                                                    <td>
                                                        <span className="ttl">J 조</span>
                                                        <span className="txt">12:40 ~</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <span className="ttl">TEAMS만 참가 시</span>
                                                        <span className="txt">11:40 ~</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="dt-txt">
                                        <p>원활한 식사를 위해 조별 식사 시간을 지켜주세요.</p>
                                        <p>리셉션에서 Finalist/Teams 등록 시 동반 1인을<br/>
                                            포함한 앱 쿠폰이 발급됩니다.</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">이벤트</p>
                                    <div className="txt">
                                        <p>당일 현장에서 공개되는 이벤트 성공 시 9.81파크의<br/>
                                            브랜드 스토어 가라지981의 인기 굿즈가 제공됩니다.</p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">Finalist/Teams 현장 인터뷰</p>
                                    <div className="txt">
                                        <p>현장 인터뷰는 필수 참석해주시고, 오후 2차 주행이<br/>
                                            종료된 Finalist/Teams는 인터뷰 존(B2)에서 인터뷰를 진행해 주시기 바랍니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info">
                            <div className="inner">
                                <p className="ttl box-ttl">출발 대기 시간표</p>

                                <div className="txt-wrap">
                                    <div className="txt">
                                        <p>경기에 참가하는 레이서들은 출발 대기 시간까지 반드시 B2F 대기존으로 모여 주시기 바랍니다.</p>
                                    </div>

                                    <div className="table">
                                        <p className="ttl">SINGLES</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <p className="txt">1차 주행 조</p>
                                                    </td>
                                                    <td colSpan="2">
                                                        <p className="txt">2차 주행 조</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="ttl">A/B조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">11:30</p>
                                                    </td>
                                                    <th>
                                                        <p className="ttl">1/2조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">13:50</p>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="ttl">C/D조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">11:40</p>
                                                    </td>
                                                    <th>
                                                        <p className="ttl">3/4조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">14:00</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="ttl">E/F조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">11:50</p>
                                                    </td>
                                                    <th>
                                                        <p className="ttl">5/6조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">14:10</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="ttl">G/H조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">12:00</p>
                                                    </td>
                                                    <th>
                                                        <p className="ttl">7/8조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">14:20</p>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="ttl">I/J조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">12:10</p>
                                                    </td>
                                                    <th>
                                                        <p className="ttl">9/10조</p>
                                                    </th>
                                                    <td>
                                                        <p className="txt">15:00</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="dt-txt">
                                        <p>
                                            1차 주행 조 : Finalist 등록 시 배부<br/>
                                            (GROC 챔피언십 파이널 진출 기록의 역순)
                                        </p>
                                        <p>
                                            2차 주행 조 : 1차 주행 종료 후 현장 발표<br/>
                                            (대회 당일 1차 주행 기록의 역순)
                                        </p>
                                    </div>

                                    <div className="table">
                                        <p className="ttl">TEAMS</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <p className="txt">
                                                            준결승 (4팀)<br/>
                                                            10:50
                                                        </p>
                                                    </td>
                                                    <td colSpan="2">
                                                        <p className="txt">
                                                            결승 (2팀)<br/>
                                                            14:50
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="dt-txt">
                                        <p>
                                            준결승 팀 : GROC 2024 시즌 TEAMS 4강 진출팀
                                        </p>
                                    </div>
                                </div>

                                <div className="txt-wrap">
                                    <p className="ttl">대회 규정 및 유의사항</p>
                                    <div className="dt-txt">
                                        <p>
                                            GROC 2024 챔피언십 파이널 규정집은 11월 13일<br/>
                                            부터 확인 가능하며, 별도의 공지 없이 업데이트 될 수<br/>
                                            있습니다.
                                        </p>
                                        <p>
                                            대회 당일 현장 상황(기상 등)에 의해 세부 일정은<br/>
                                            변경될 수 있으며, 변동 시 별도 안내 예정입니다.
                                        </p>
                                    </div>
                                    <div className="btn-area">
                                        <a href="https://asset.981park.com/www/common/web/img/pdf/GROC-2024-Championship-Final-Rule.pdf"
                                           target="_blank" rel="noopener noreferrer" className="btn"><span className="txt">대회 규정 바로가기 ></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MMSComponents;

